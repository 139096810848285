export function dateToStr(datetime){
    var datetime = new Date(datetime);
    var year = datetime.getFullYear();
    var month = datetime.getMonth()+1;//js从0开始取
    var date = datetime.getDate();
    month = month < 10 ?  "0" + month : month ;
    date  = date<10 ?  "0" + date : date;
    return year + "年" + month + "月" + date + '日';
}
    
export function dateToStrCopy(datetime){
    var datetime = new Date(datetime);
    var year = datetime.getFullYear();
    var month = datetime.getMonth()+1;//js从0开始取
    var date = datetime.getDate();
    month = month < 10 ?  "0" + month : month ;
    date  = date<10 ?  "0" + date : date;
    return year + "-" + month + "-" + date ;
}

export const formatter = (type, val) => {
    if (type === 'year') {
      return val + '年';
    }
    if (type === 'month') {
      return val + '月';
    }
    if (type === 'day') {
      return val + '日';
    }
    return val;
};